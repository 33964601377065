import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, forkJoin, map, switchMap, tap } from 'rxjs';
import { IPRLookupDetailsResponse } from 'src/app/_models/pr.models';
import { MONTHSLIST } from 'src/app/_static/months';
import {
  ApproverViewModel,
  EmployeeDashboardViewModel,
  IQueueStatisticsViewModel,
  LookupDetailsViewModel,
  QueueFilterViewModel,
  ServerResponseWithBody,
  UserInfoViewModel,
} from '../../_models/app.models';
import { RestService } from '../../_services/rest.service';
import { getUrlPathFragment } from '../../_static/util';
import { BudgetDetailsResponse, BudgetDetailsViewModel, DocumentDataRequestBody, DocumentDataResponseBody, DocumentDetails, GetDashboardConfiguration, SaveDasboardConfig, StatsDataResponseBody, StatsDetails, WidgetIdsConstant } from './dashboard.model';
import { ITATComponentData, ITATReportQueue } from 'src/app/_models/dashboards.models';
import { DashboardService } from 'src/app/_services/_data-sharing/dashboard.service';
import { TAT_REPORT_TYPES } from 'src/app/_constants/dashboard.constants';
import { ExcelService } from 'src/app/_services/_shared_service/excel.service';
import { defaultLocaleWeekdaysMin } from 'ngx-bootstrap/chronos/locale/locale.class';
import { CamRestService } from 'src/app/_services/cam-rest.service';
import { JwtHelperService } from '@auth0/angular-jwt';
@Component({
  selector: 'tg-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss'],
})
export class UserDashboardComponent implements OnInit {
  viewModel?: EmployeeDashboardViewModel;
  pastMonthsData: any;
  chartConfiguration: any;
  selectedMonth!: number;
  selectedYear!: string;
  prQueue!: IQueueStatisticsViewModel
  poQueue!: IQueueStatisticsViewModel
  invoiceQueue!: IQueueStatisticsViewModel
  budegtDetails!: BudgetDetailsResponse;
  uploadTATData!: any;
  overallTATData!: any;
  paymentTATData!: any;
  TATByTitleData!: any;
  budgetDetailsTableData!: BudgetDetailsViewModel;
  financialYearList: IPRLookupDetailsResponse[] = [];
  monthsList: LookupDetailsViewModel[] = MONTHSLIST;
  dashboardConfig!: GetDashboardConfiguration[];
  documentRequest!: DocumentDataRequestBody;
  menuVisible: boolean = false;
  draggedItem: any;
  displayMenu: boolean = false;
  widgetBody!: GetDashboardConfiguration[];
  originalConfig: GetDashboardConfiguration[] = [];
  hasChanges: boolean = false;
  sortedWidgets: any;
  widgetData: any = {};
  filterParams!: QueueFilterViewModel;
  page: number = 1;
  pageSize: number = 5;
  totalRecords: number = 0;
  vendorCountTitle = 'Total Vendor Count';
  invoiceCountTitle = 'Total Invoice Count';
  taxableAmountTitle = 'Total Taxable Amount';
  invoiceAmountTitle = 'Total Invoice Amount';
  taxAmountTitle = 'Total Tax Amount';
  vendorCount = '1760';
  invoiceCount = '3509';
  taxableAmount = '2509755';
  invoiceAmount = '3525562';
  taxAmount = '3609985';
  vendorCountInfo = 'New Unique Vendors';
  additionalInfo = 'for last month';
  iconVendorCount = 'bi bi-file-earmark-person';
  iconInvoiceCount = 'bi bi-file-earmark';
  iconTaxableAmount = 'bi bi-briefcase';
  iconInvoiceAmount = 'fa fa-inr';
  iconTaxAmount = 'bi bi-percent';
  statsData!: StatsDetails;

  approversData: ApproverViewModel[] = [];
  TATDropdown1Data: string[] = [];
  TATDropdown2Data: string[] = [];

  TATDropdown3Data: string[] = [];
  TATDropdown4Data: string[] = [];
  isUserAdmin!: boolean;
  constructor(private restService: RestService,
    private router: Router,
    private _dashboardDataSharingService: DashboardService,
    private _excelService: ExcelService,
    private _camRestService: CamRestService,
    public jwtHelper: JwtHelperService,
  ) {

  }

  ngOnInit(): void {
    const access_token = sessionStorage.getItem('pxp_token');
    if (!!access_token) {
      const valid = !this.jwtHelper.isTokenExpired(access_token);
      if (valid) {
        const jwtViewModel = this.jwtHelper.decodeToken<UserInfoViewModel>(access_token);
        this.isUserAdmin = jwtViewModel.permissions.some(permission =>
          permission.authority.includes("Role: admin")
        );
      }
    }
    this.filterParams = this.initPage();
    this.getFinancialYearListAPICall().subscribe((res: IPRLookupDetailsResponse[]) => {
      this.financialYearList = res;
      this.setInitialValues();
    })
  }

  private initPage(): QueueFilterViewModel {
    return <QueueFilterViewModel>{
      page: 1,
      pageSize: 5,
      searchString: "",
      searchCriteria: <Record<string, string[]>>{},
      sortByColumnName: null,
      sortOrder: 1,
    }
  }

  getNextPage(event: any): void {
    this.filterParams.page = event.first / event.rows + 1;
    this.filterParams.pageSize = event.rows;
    this.filterParams.sortByColumnName = event.sortField === undefined ? null : event.sortField;
    this.filterParams.sortOrder = event.sortOrder;
    this.pageSize = event.rows;
    this.restService
      .post<QueueFilterViewModel,
        ServerResponseWithBody<BudgetDetailsResponse>>(
          getUrlPathFragment('dashboards', 'widget', 'budget-details'),
          this.filterParams
        )
      .subscribe(data => {
        this.budegtDetails = data.body;
        this.TATDropdown1Data = Array.from(
          new Set(this.approversData?.map((v: ApproverViewModel) => v.approvalMatcher))
        );

        this.TATDropdown2Data = this.getUniqueApprovalMatchValues(
          this.approversData,
          this.TATDropdown1Data[0]
        );

        this.TATDropdown3Data = Array.from(
          new Set(this.approversData?.map((v: ApproverViewModel) => v.approvalTitle))
        )

        this.TATDropdown4Data = this.getUniqueApprovalMatchValuesByTitle(
          this.approversData,
          this.TATDropdown3Data[0]
        );

        this.widgetData = {
          1: this.formatData(1, this.invoiceQueue),
          2: this.formatData(2, this.prQueue),
          3: this.formatData(3, this.poQueue),
          4: this.formatData(4, this.budegtDetails),
          5: this.formatTATReportData('Upload TAT', 'Segment - Digital', this.uploadTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.UPLOAD),
          6: this.formatTitleTATReportData(`${this.TATDropdown3Data[0]} TAT`, 'Segment - Digital', this.TATByTitleData, this.TATDropdown3Data, this.TATDropdown4Data, TAT_REPORT_TYPES.REVIEWED),
          7: this.formatTATReportData('Overall TAT', 'Segment - Digital', this.overallTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.OVERALL),
          8: this.formatTATReportData('Payment TAT', 'Segment - Digital', this.paymentTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.PAYMENT)
        };
      }
      );
  }

  private loadData(): void {
    this.restService
      .read<ServerResponseWithBody<EmployeeDashboardViewModel>>(
        getUrlPathFragment('dashboard')
      )
      .pipe(map((v) => v.body))
      .subscribe((v) => {
        this.viewModel = v;
      });
    this.getStatsData(this.selectedMonth)
    this.getDashboardConfig();
  }

  private formatData(widgetId: number, apiData: any) {
    const nameMap: { [key: string]: string } = {
      'Purchase Request': 'PR',
      'Purchase Order': 'PO',
      'Invoice': 'Invoice',
      'Budget Details': 'Budget Details'
    };

    const widgetName = this.dashboardConfig.find(w => w.widgetId === widgetId)?.name == 'Purchase Requisition' ? 'Purchase Request' : this.dashboardConfig.find(w => w.widgetId === widgetId)?.name || 'Unknown';

    return {
      name: nameMap[widgetName] || widgetName,
      data: apiData
    };
  }

  getWidgetComponent(widget: any) {
    switch (widget.widgetId) {
      case 1:
        return 'tg-document-detail-count-widget';
      case 2:
        return 'tg-document-detail-count-widget';
      case 3:
        return 'tg-document-detail-count-widget';
      case 4:
        return 'tg-budget-details-widget'; // Adjust this if needed
      case 5:
        return 'tg-finance-tat-report-widget';
      case 6:
        return 'tg-finance-tat-report-widget';
      case 7:
        return 'tg-finance-tat-report-widget';
      case 8:
        return 'tg-finance-tat-report-widget';
      // Add more cases for different widget types
      default:
        return '';
    }
  }

  getWidgetData(widgetId: number) {
    return this.widgetData[widgetId] || {};
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.widgetBody, event.previousIndex, event.currentIndex);
    this.updateSequenceIds();
    this.checkForChanges();
  }

  private updateSequenceIds() {
    this.widgetBody.forEach((item, index) => {
      item.sequenceId = index + 1;
    });
  }

  onSwitchChange(item: any) {
    this.hasChanges = true
  }

  saveChanges() {
    // Save logic here
    this.widgetBody.forEach(newWidget => {
      const existingWidgetIndex = this.dashboardConfig.findIndex(widget => widget.id === newWidget.id);

      if (existingWidgetIndex !== -1) {
        // Update existing widget with the new data
        this.dashboardConfig[existingWidgetIndex] = { ...this.dashboardConfig[existingWidgetIndex], ...newWidget };
      } else {
        // Add new widget if it's not already in the dashboardConfig
        this.dashboardConfig.push(newWidget);
      }
    });
    const config = { configs: this.dashboardConfig }
    this.restService.post
      <SaveDasboardConfig, ServerResponseWithBody<any>>
      (getUrlPathFragment('dashboards', 'configurations'), config)
      .pipe(map(res => res.body)).subscribe((response: any) => {
        // Optionally, clear the file list and reset totalSize
        this.getDashboardConfig();
      }
      )
    this.menuVisible = false
    this.hasChanges = false;
  }

  resetChanges() {
    // Reset to original data
    this.widgetBody = JSON.parse(JSON.stringify(this.originalConfig));
    // If you have a separate property for toggle states, reset it here
    // if (this.originalConfig) {
    //   this.widgetBody.forEach(widget => {
    //     const originalWidget = this.originalConfig.find(original => original.widgetId === widget.widgetId);
    //     widget.isActive = originalWidget ? originalWidget.isActive : false; // Default to false if not found
    //   });
    // }
    this.updateSequenceIds();
    this.hasChanges = false;
  }

  checkForChanges() {
    this.hasChanges = JSON.stringify(this.widgetBody) !== JSON.stringify(this.dashboardConfig);
  }

  onClose() {
    this.menuVisible = false
  }

  onFinancialYearChange() {
    this.selectedMonth = 0
    this.getStatsData(this.selectedMonth)
    this.getDashboardConfig();

  }

  onMonthChange(month: any) {
    this.getStatsData(month)
    this.getDashboardConfig();
  }

  navigateToDocument(status: string, name: string) {
    if (name == "Purchase Requisition") {
      this.router.navigate(['/purchase-requisition'], { state: { status } });
    }
    if (name == "Purchase Order") {
      this.router.navigate(['/purchase-order'], { state: { status } });
    }
    if (name == "Invoice") {
      this.router.navigate(['/invoice'], { state: { status } });
    }
    // this.router.navigate(['/purchase-requisition'], { state: { status } });
  }

  getSearchValue(value: string) {
    this.filterParams = this.initPage();
    this.pageSize = 5;
    this.filterParams.searchCriteria = { 'budgetCode': [value] };
    this.restService
      .post<QueueFilterViewModel,
        ServerResponseWithBody<BudgetDetailsResponse>>(
          getUrlPathFragment('dashboards', 'widget', 'budget-details'),
          this.filterParams
        )
      .subscribe(data => {
        this.budegtDetails = data.body;
        this.widgetData = {
          1: this.formatData(1, this.invoiceQueue),
          2: this.formatData(2, this.prQueue),
          3: this.formatData(3, this.poQueue),
          4: this.formatData(4, this.budegtDetails),
        };
      }
      );
  }

  setInitialValues() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-based index
    const currentYear = currentDate.getFullYear();

    // Set selectedMonth to the current month
    this.selectedMonth = currentMonth;

    // Determine the financial year
    let financialYear: string;
    if (currentMonth >= 4) { // April to December
      financialYear = `${currentYear}-${currentYear + 1}`;
    } else { // January to March
      financialYear = `${currentYear - 1}-${currentYear}`;
    }
    this.financialYearList = this.financialYearList.filter(year => year.value >= financialYear);
    // Find the matching financial year from the list and set it
    const matchingYear = this.financialYearList.find(year => year.value === financialYear);
    if (matchingYear) {
      this.selectedYear = matchingYear.value;
    }
    this.loadData();
  }

  getDropdown2Data(event: any) {
    this.TATDropdown2Data = this.getUniqueApprovalMatchValues(this.approversData, event);
    forkJoin([
      this.getTATReportData(TAT_REPORT_TYPES.UPLOAD, event, this.TATDropdown2Data[0]),
      this.getTATReportData(null, event, this.TATDropdown2Data[0]),
      this.getTATReportData(TAT_REPORT_TYPES.PAYMENT, event, this.TATDropdown2Data[0])]
    ).subscribe(res => {
      this.widgetData = {
        ...this.widgetData,
        5: this.formatTATReportData('Upload TAT', `Segment - Digital`, this.uploadTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.UPLOAD),
        7: this.formatTATReportData('Overall TAT', `Segment - Digital`, this.overallTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.OVERALL),
        8: this.formatTATReportData('Payment TAT', `Segment - Digital`, this.paymentTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.PAYMENT),
      };
      this._dashboardDataSharingService.setDropdown2(this.TATDropdown2Data[0]);
    })
  }

  getDropdown4Data(event: any) {
    this.TATDropdown4Data = this.getUniqueApprovalMatchValuesByTitle(this.approversData, event);
    forkJoin([
      this.getTATReportData(TAT_REPORT_TYPES.REVIEWED, event, this.TATDropdown4Data[0]),
    ]).subscribe(res => {
      this.widgetData = {
        ...this.widgetData,
        6: this.formatTitleTATReportData(`${event} TAT`, `Segment - Digital`, this.TATByTitleData, this.TATDropdown3Data, this.TATDropdown4Data, TAT_REPORT_TYPES.REVIEWED),
      }
    })
  }

  getDataAfterDropdown2Change(event: any) {
    this._dashboardDataSharingService.dropdown1$.subscribe(value1 => {
      forkJoin([
        this.getTATReportData(TAT_REPORT_TYPES.UPLOAD, value1, event),
        this.getTATReportData(null, value1, event),
        this.getTATReportData(TAT_REPORT_TYPES.PAYMENT, value1, event)]
      ).subscribe(res => {
        this.widgetData = {
          ...this.widgetData,
          5: this.formatTATReportData('Upload TAT', `Segment - Digital`, this.uploadTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.UPLOAD),
          7: this.formatTATReportData('Overall TAT', `Segment - Digital`, this.overallTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.OVERALL),
          8: this.formatTATReportData('Payment TAT', `Segment - Digital`, this.paymentTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.PAYMENT)
        };
      })
    })
  }

  getDataAfterDropdown4Change(event: any) {
    this._dashboardDataSharingService.dropdown3$.subscribe(value3 => {
      forkJoin([this.getTATReportData(TAT_REPORT_TYPES.REVIEWED, value3, event)])
        .subscribe(() => {
          this.widgetData = {
            ...this.widgetData,
            6: this.formatTitleTATReportData(`${value3} TAT`, `Segment - Digital`, this.TATByTitleData, this.TATDropdown3Data, this.TATDropdown4Data, TAT_REPORT_TYPES.REVIEWED)
          };
        })
    })
  }

  // Get Data API Calls
  private getPurchaseRequest(): Observable<any> {
    this.documentRequest = { financialYear: this.selectedYear || '', month: this.selectedMonth || null, name: 'Purchase Request' }
    return this.restService.post
      <DocumentDataRequestBody, ServerResponseWithBody<IQueueStatisticsViewModel>>
      (getUrlPathFragment('dashboards', 'widget', WidgetIdsConstant.PURCHASE_REQUEST), this.documentRequest)
      .pipe(
        tap(data => this.prQueue = data.body)
      );
  }

  private getInvoiceDetails(): Observable<any> {
    this.documentRequest = { financialYear: this.selectedYear || '', month: this.selectedMonth || null, name: 'Invoice' }
    return this.restService.post
      <DocumentDataRequestBody, ServerResponseWithBody<IQueueStatisticsViewModel>>
      (getUrlPathFragment('dashboards', 'widget', WidgetIdsConstant.INVOICE), this.documentRequest)
      .pipe(
        tap(data => this.invoiceQueue = data.body)
      );
  }

  private getPurchaseOrder(): Observable<any> {
    this.documentRequest = { financialYear: this.selectedYear || '', month: this.selectedMonth || null, name: 'Purchase Order' }
    return this.restService.post
      <DocumentDataRequestBody, ServerResponseWithBody<IQueueStatisticsViewModel>>
      (getUrlPathFragment('dashboards', 'widget', WidgetIdsConstant.PURCHASE_ORDER), this.documentRequest)
      .pipe(
        tap(data => this.poQueue = data.body)
      );
  }

  private getFinancialYearListAPICall(): Observable<IPRLookupDetailsResponse[]> {
    return this.restService.read<ServerResponseWithBody<IPRLookupDetailsResponse[]>>(
      (getUrlPathFragment('lookup', 'FinancialYear'))
    ).pipe(map(res => res.body));
  }

  private getBudgetDetails(): Observable<any> {
    return this.restService
      .post<QueueFilterViewModel,
        ServerResponseWithBody<BudgetDetailsResponse>>(
          getUrlPathFragment('dashboards', 'widget', 'budget-details'),
          this.filterParams
        )
      .pipe(
        tap(data => this.budegtDetails = data.body)
      );
  }

  private getStatsData(month: number) {
    this.documentRequest = { financialYear: this.selectedYear || '', month: month !== 0 ? month : null, name: '' }
    return this.restService.post
      <DocumentDataRequestBody, ServerResponseWithBody<StatsDataResponseBody>>
      (getUrlPathFragment('dashboards', 'widget', 'stats', 'invoice'), this.documentRequest)
      .pipe(map((v) => v.body)).
      subscribe((data) => {
        this.statsData = data.data;
      })
  }

  private getDashboardConfig() {
    this.restService.read<ServerResponseWithBody<GetDashboardConfiguration[]>>(
      getUrlPathFragment('dashboards', 'configurations')
    )
      .pipe(map((v) => v.body))
      .subscribe((v) => {
        this.dashboardConfig = v;
        this.sortedWidgets = this.dashboardConfig.sort((a, b) => a.sequenceId - b.sequenceId);
        this.originalConfig = JSON.parse(JSON.stringify(this.sortedWidgets)); // Deep copy
        this.widgetBody = JSON.parse(JSON.stringify(this.sortedWidgets));
        forkJoin([
          this.getInvoiceDetails(),
          this.getPurchaseOrder(),
          this.getPurchaseRequest(),
          this.getBudgetDetails(),
          this.getTATDropdownData(),
        ]).pipe(
          switchMap((res: any) => {
            this.approversData = res[4];

            this.TATDropdown1Data = Array.from(
              new Set(this.approversData?.map((v: ApproverViewModel) => v.approvalMatcher))
            );

            this.TATDropdown2Data = this.getUniqueApprovalMatchValues(
              this.approversData,
              this.TATDropdown1Data[0]
            );

            this.TATDropdown3Data = Array.from(
              new Set(this.approversData?.map((v: ApproverViewModel) => v.approvalTitle))
            )

            this.TATDropdown4Data = this.getUniqueApprovalMatchValuesByTitle(
              this.approversData,
              this.TATDropdown3Data[0]
            );

            this.widgetData = {
              1: this.formatData(1, this.invoiceQueue),
              2: this.formatData(2, this.prQueue),
              3: this.formatData(3, this.poQueue),
              4: this.formatData(4, this.budegtDetails),
            }
            return forkJoin([
              this.getTATReportData(TAT_REPORT_TYPES.UPLOAD, this.TATDropdown1Data[0], this.TATDropdown2Data[0]),
              this.getTATReportData(TAT_REPORT_TYPES.REVIEWED, this.TATDropdown3Data[0], this.TATDropdown4Data[0]),
              this.getTATReportData(null, this.TATDropdown1Data[0], this.TATDropdown2Data[0]),
              this.getTATReportData(TAT_REPORT_TYPES.PAYMENT, this.TATDropdown1Data[0], this.TATDropdown2Data[0])]
            )
          }
          ))
          .subscribe(() => {
            this.widgetData = {
              ...this.widgetData,
              5: this.formatTATReportData('Upload TAT', 'Segment - Digital', this.uploadTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.UPLOAD),
              6: this.formatTitleTATReportData(`${this.TATDropdown3Data[0]} TAT`, 'Segment - Digital', this.TATByTitleData, this.TATDropdown3Data, this.TATDropdown4Data, TAT_REPORT_TYPES.REVIEWED),
              7: this.formatTATReportData('Overall TAT', 'Segment - Digital', this.overallTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.OVERALL),
              8: this.formatTATReportData('Payment TAT', 'Segment - Digital', this.paymentTATData, this.TATDropdown1Data, this.TATDropdown2Data, TAT_REPORT_TYPES.PAYMENT),
            };
            this._dashboardDataSharingService.setDropdown1(this.TATDropdown1Data[0]);
            this._dashboardDataSharingService.setDropdown2(this.TATDropdown2Data[0]);
          });
      })
  }

  private getUniqueApprovalMatchValues(data: any[], matcher: string): string[] {
    return [
      ...new Set(
        data
          .filter(item => item.approvalMatcher === matcher)
          .map(item => item.approvalMatchValue)
      )
    ];
  }

  private getUniqueApprovalMatchValuesByTitle(data: any[], matcher: string): string[] {
    return [
      ...new Set(
        data
          .filter(item => item.approvalTitle === matcher)
          .map(item => item.approvalMatchValue)
      )
    ];
  }

  private formatTATReportData(
    centerText1: string,
    centerText2: string,
    data: any[],
    dropdown1Data: any[],
    dropdown2Data: any[],
    reportType: string
  ): ITATComponentData {
    let responseData: ITATComponentData = {
      centerText1,
      centerText2,
      data: data?.map(r => {
        return {
          name: r.ageing,
          y: r.noOfInvoices,
          invoiceAmount: r.totalInvoiceAmount,
          mix: r.mix,
          color: r.indicator
        } as ITATReportQueue;
      }),
      isSwitchDropdown: true,
      dropdown1Data,
      dropdown2Data,
      reportType
    }
    return responseData;
  }

  private formatTitleTATReportData(
    centerText1: string,
    centerText2: string,
    data: any[],
    dropdown3Data: any[],
    dropdown4Data: any[],
    reportType: string): ITATComponentData {
    let responseData: ITATComponentData = {
      centerText1,
      centerText2,
      data: data?.map(r => {
        return {
          name: r.ageing,
          y: r.noOfInvoices,
          invoiceAmount: r.totalInvoiceAmount,
          mix: r.mix,
          color: r.indicator
        } as ITATReportQueue;
      }),
      isTATByTitle: true,
      dropdown3Data,
      dropdown4Data,
      reportType
    }
    return responseData;
  }

  exportExcel(isExport: boolean, widgetId: number, widgetData: any) {
    if (isExport) {
      switch (widgetId) {
        // Upload TAT Case
        case 1: {
          this.mapPRExportData(widgetData[widgetId]?.data?.data, "Invoice");
        }
          break;
        case 2: {
          this.mapPRExportData(widgetData[widgetId]?.data?.data, "Purchase Requisition");
        }
          break;
        case 3: {
          this.mapPRExportData(widgetData[widgetId]?.data?.data, "Purchase Order");
        }
          break;
        case 4: {
          this.mapBudgetExportData(widgetData[widgetId]?.data);
        }
          break;
        case 5: {
          this.mapTATExportData(widgetData[widgetId]?.data, TAT_REPORT_TYPES.UPLOAD);
        }
          break;
        case 6: {
          this.mapTATExportData(widgetData[widgetId]?.data, TAT_REPORT_TYPES.REVIEWED);
        }
          break;
        case 7: {
          this.mapTATExportData(widgetData[widgetId]?.data, TAT_REPORT_TYPES.OVERALL);
        }
          break;
        case 8: {
          this.mapTATExportData(widgetData[widgetId]?.data, TAT_REPORT_TYPES.PAYMENT);
        }
          break;
      }
    }
  }

  private mapPRExportData(data: IQueueStatisticsViewModel, title: string) {
    let requestData = [{
      key: "Draft",
      amount: data.draftAmount,
      count: data.draftCount
    },
    {
      key: "Submitted",
      amount: data.submittedAmount,
      count: data.submittedCount
    },
    {
      key: "Approved",
      amount: data.approvedAmount,
      count: data.approvedCount
    },
    {
      key: "Rejected",
      amount: data.rejectedAmount,
      count: data.rejectedCount
    },
    {
      key: "Hold",
      amount: data.holdAmount,
      count: data.holdCount
    }
    ];

    if (title === 'Invoice') {
      let invoice = [
        {
          key: "Parked",
          amount: data.parkedAmount,
          count: data.parkedCount
        },
        {
          key: "Posted",
          amount: data.postedAmount,
          count: data.postedCount
        },
        {
          key: "Paid",
          amount: data.paidAmountAmount,
          count: data.paidCount
        }
      ]
      requestData = [...requestData, ...invoice];
    }

    let excelData = requestData.map(item => {
      return {
        "Status": item.key,
        "Amount": item.amount,
        "Count": item.count
      }
    })
    this._excelService.exportToCSV(excelData, title);
  }

  private mapBudgetExportData(budgetData: BudgetDetailsResponse) {
    let excelData = budgetData?.data?.map(budget => {
      return {
        "Budget Code": budget.budgetCode,
        "Type": budget.type,
        "Amount Allocated": budget.amount,
        "Balance Amount": budget.balanceAmount,
        "No. Of POs Raised": budget.purchaseOrderCount,
        "No. Of Invoices Raised": budget.invoiceCount
      }
    })
    this._excelService.exportToCSV(excelData, "Budget Data");
  }

  private mapTATExportData(data: any, widgetName: string) {
    let excelData = data?.map((item: any) => {
      return {
        "Aging": item.name,
        "No Of Invoices": item.y,
        "Total Invoice Amount": item.invoiceAmount,
        "mix": item.mix
      }
    })
    this._excelService.exportToCSV(excelData, widgetName);
  }

  private getTATReportData(reportType: string | null, value1: string, value2: string): Observable<any> {
    let body = {
      widgetName: reportType,
      [value1]: value2,
    }
    return this.restService.post<any, ServerResponseWithBody<any>>(
      getUrlPathFragment('dashboards', 'invoice-ageing-report'), body)
      .pipe(map(res => res.body))
      .pipe(map(res => res.data))
      .pipe(tap(res => {
        if (reportType === TAT_REPORT_TYPES.UPLOAD) {
          this.uploadTATData = res
        } else if (reportType === TAT_REPORT_TYPES.REVIEWED) {
          this.TATByTitleData = res
        } else if (reportType === TAT_REPORT_TYPES.OVERALL) {
          this.overallTATData = res
        } else if (reportType === TAT_REPORT_TYPES.PAYMENT) {
          this.paymentTATData = res
        }
      }));
  }

  private getTATDropdownData(): Observable<ApproverViewModel[]> | null {
    if (this.isUserAdmin) {
      return this.restService
        .read<ServerResponseWithBody<ApproverViewModel[]>>(
          getUrlPathFragment('approvers', 'all')
        )
        .pipe(map((v) => v.body))
    }
    else {
      return null
    }
  }
}
