import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';
import { CamRestService } from 'src/app/_services/cam-rest.service';
import { SafeUrl } from '@angular/platform-browser';
import { skipGlobalErrorHandling } from 'src/app/_constants/http-constants';
import { UserUpdateNotificationService } from 'src/app/_services/user-update-notification.service';
@Component({
  selector: 'tg-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent implements OnInit {
  items: MenuItem[] = [];

  adminMenuItems: MenuItem[] = [
    {
      label: 'Policy',
      icon: 'pi pi-cog',
      routerLink: '/admin/expense-metadata',
    },
    {
      label: 'Approver',
      icon: 'pi pi-users',
      routerLink: '/admin/approver',
    },
    {
      label: 'Location',
      icon: 'pi pi-map-marker',
      routerLink: '/admin/location-master',
    },
    {
      label: 'Delegations',
      icon: 'pi pi-reply',
      routerLink: '/delegation/admin',
    },
    {
      label: 'Report Queue',
      icon: 'pi pi-list',
      routerLink: '/queue/admin',
    },
    {
      label: 'CEM Master Data',
      icon: 'pi pi-th-large',
      items: [
        {
          label: 'View All Masters',
          icon: 'pi pi-cog',
          items: [
            {
              label: 'Branch Master',
              routerLink: '/cem/branch',
            },
            {
              label: 'Cost Center Master',
              routerLink: '/cem/cost-center',
            },
            {
              label: 'Department Master',
              routerLink: '/cem/department',
            },
            {
              label: 'Employee Master',
              routerLink: '/cem/employee-master',
            },
            {
              label: 'Employee Grade Master',
              routerLink: '/cem/employee-grade',
            },
            {
              label: 'Employee Type Master',
              routerLink: '/cem/employee-type',
            },
            {
              label: 'Supplier Master',
              routerLink: '/master/supplier-master-queue'
            },
            {
              label: 'Item Master',
              routerLink: '/item'
            },
            {
              label: 'GL Master',
              routerLink: '/master/gl-master-queue'
            },
            {
              label: 'Hierarchy',
              routerLink: '/hierarchy/add-edit'
            },
            {
              label: 'Key-Value Mapping',
              routerLink: '/hierarchy/key-value-mapping'
            },
            {
              label: 'Segment Ratio Master',
              routerLink: '/segment-ratio-master'
            }
          ],
        },
        {
          label: 'View MasterData Mappings',
          icon: 'pi pi-users',
          routerLink: '/master/master-mapping-queue'
        },
      ],
    },
  ];

  budgetMenuItems: MenuItem[] = [
    {
      label: 'View All Budgets',
      icon: 'bi bi-bag-plus',
      routerLink: '/budget',
    },
    {
      label: 'Create New Budget',
      icon: 'bi bi-wallet2',
      routerLink: '/budget/create',
    }
  ];

  username = '';
  userEmail!: string;
  profileImage!: SafeUrl | null;
  constructor(
    public authService: AuthService,
    private router: Router,
    private _camRestService: CamRestService,
    private userUpdateNotifyService: UserUpdateNotificationService
  ) { }

  ngOnInit(): void {
    const employeeDetails = sessionStorage.getItem('employee_details');
    if (!!employeeDetails) {
      this.userEmail = JSON.parse(employeeDetails).employeeDetail.email

      this.userEmail && this.getCompanyProfile(this.userEmail);
    }
    this.userUpdateNotifyService.profileUpdated$.subscribe((updated) => {
      if (updated) {
        this.getCompanyProfile(this.userEmail);
      }
    });
    this.authService.getAuth().subscribe((v) => {
      this.items = [
        {
          label: 'Profile',
          icon: 'pi pi-user',
          command: () => {
            this.router.navigate(['/profile']);
          },
        },
        // {
        //   label: 'Entitlement',
        //   icon: 'pi pi-briefcase',
        //   command: () => {
        //     this.router.navigate(['/entitlement']);
        //   },
        // },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          command: () => {
            this.authService.logout();
          },
        },
      ];

      let parts = v?.sub.split('@') ?? [];
      this.username = parts[0]?.replace('.', ' ') ?? '';
    });

  }
  private getCompanyProfile(userEmail: string) {
    this._camRestService.getUserProfileImage(userEmail, skipGlobalErrorHandling)
      .subscribe(res => {
        this.profileImage = this.convertBase64ToImageUrl(res.userPhoto);
      })
  }
  private convertBase64ToImageUrl(base64String: string): string {
    if (!base64String.startsWith('data:image')) {
      return `data:image/png;base64,${base64String}`;
    }
    return base64String;
  }
}
