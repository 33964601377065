import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserUpdateNotificationService {

  constructor() { }
  private profileUpdatedSubject = new BehaviorSubject<boolean>(false);
  profileUpdated$ = this.profileUpdatedSubject.asObservable();

  notifyProfileUpdated() {
    this.profileUpdatedSubject.next(true);
  }
}
